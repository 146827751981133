import { defineStore } from 'pinia';
import { useQuery } from '@pinia/colada';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import type { Opportunity } from '~/interfaces/opportunities/main';
import type { Person } from '~/interfaces/persons/main';
import type { DropdownOption } from '~/types/main';

/**
 * Store managing opportunities and related data.
 * Handles both single opportunity and opportunities list operations.
 */
export const useOpportunitiesStore = defineStore('OpportunitiesStore', () => {
  // Router
  const route = useRoute();

  // UI State
  const editSection = ref<string>('family-situation');
  const advancedMode = ref<boolean>(false);
  const selectedOpportunities = ref<Opportunity[]>([]);

  // Persons State
  const hasCoborrower = ref<boolean>(false);
  const borrower = ref<Person | null>(null);
  const coBorrower = ref<Person | null>(null);
  const concernedPersonsDropDownList = ref<DropdownOption[][]>([]);

  // Data State
  const opportunityRef = ref<Opportunity | null>(null);
  const totalRecords = ref<number>(0);

  /**
   * Current opportunity ID from either the opportunity reference or route params
   */
  const opportunityId = computed<string>(() =>
    (route?.params?.opportunityHashId?.toString() ?? opportunityRef.value?.hashId ?? route.query.hashId as string) || '',
  );

  // Query Keys
  const opportunitiesKey = computed<[string]>(() => ['opportunities']);
  const opportunityKey = computed<[string, string]>(() => ['opportunity', opportunityId.value ?? '']);

  /**
   * Query configuration for fetching opportunities list
   */
  const opportunitiesQuery = import.meta.env.TEST
    ? {
        state: ref({ data: [] }),
        asyncStatus: ref('idle'),
        refetch: (): Promise<void> => Promise.resolve(),
      }
    : useQuery<Opportunity[]>({
        key: opportunitiesKey,
        query: async () => {
          const response = await useAuthFetch('opportunities');
          totalRecords.value = response.data.value.meta?.totalItems ?? 0;
          return response.data.value.data ?? [];
        },
        staleTime: 0,
        enabled: false,
      });

  /**
   * Query configuration for fetching a single opportunity
   */
  const opportunityQuery = import.meta.env.TEST
    ? {
        state: ref({ data: null }),
        asyncStatus: ref('idle'),
        refetch: (): Promise<void> => Promise.resolve(),
      }
    : useQuery<Opportunity>({
        key: opportunityKey,
        query: async () => {
          const id = opportunityId.value;
          if (!id) { return null; }
          const response = await useAuthFetch(`opportunity/${id}`);
          return response.data.value.data ?? null;
        },
        staleTime: 0,
        enabled: false,
      });

  // Computed Properties
  const opportunities = computed(() => opportunitiesQuery.state.value?.data ?? []);
  const opportunity = computed(() => opportunityQuery.state.value?.data ?? null);
  const loading = computed(() =>
    [opportunitiesQuery.asyncStatus.value, opportunityQuery.asyncStatus.value].includes('loading'),
  );
  const error = computed(() =>
    [opportunitiesQuery.asyncStatus.value, opportunityQuery.asyncStatus.value].includes('error'),
  );

  /**
   * Fetches the list of opportunities and updates the total records count
   */
  const fetchOpportunities = async (): Promise<void> => {
    await opportunitiesQuery.refetch();
  };

  /**
   * Fetches a single opportunity by its ID
   */
  const fetchOpportunity = async (): Promise<void> => {
    await opportunityQuery.refetch();
  };

  /**
   * Updates borrower and co-borrower references based on the current opportunity
   */
  const getOpportunityPersons = (): void => {
    const currentOpportunity = opportunity.value;
    if (!currentOpportunity?.persons) { return; }

    borrower.value = currentOpportunity.persons.find(p => p.main) || null;
    coBorrower.value = currentOpportunity.persons.find(p => !p.main) || null;
  };

  return {
    // UI State
    editSection,
    advancedMode,
    selectedOpportunities,
    hasCoborrower,

    // Persons State
    borrower,
    coBorrower,
    concernedPersonsDropDownList,

    // Data State
    opportunities,
    opportunity,
    opportunityId,
    totalRecords,

    // Methods
    fetchOpportunities,
    fetchOpportunity,
    getOpportunityPersons,

    // Status
    loading,
    error,
  };
});
